/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColumnDef } from "@tanstack/react-table";
import { Constants } from "appConstants";
import { IcoFile } from "assets/icons";
import BackofficeApplicationListFilterForm, {
	ApplicationListFilters
} from "components/BackofficeApplicationListFilterForm";
import BackofficeList, { FilterTools } from "components/BackofficeList";
import {
	ApplicationListStatusDisplay,
	asCellRenderer,
	BasicValueDisplay,
	DeadlineDisplay,
	LocaleDateShortValueDisplay,
	NaiveDateValueDisplay,
	NameValueDisplay
} from "components/BackofficeList/modules/ValueDisplays";
import Button from "components/Button";
import TooltipWrapper from "components/TooltipWrapper";
import { ErrorHelper, TableHelper } from "helpers";
import usePagination from "hooks/usePagination";
import useStabilizedState from "hooks/useStabilizedState";
import useUrlFilters from "hooks/useUrlFilters";
import { InternalUserStatusLabel } from "models/types";
import { ApplicationPageTabs } from "pages/Backoffice/ApplicationPage/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { ApplicationService } from "services/applicationService";
import { ApplicationFilterOptions } from "services/types";

export type ApplicationListItem = {
	id: string;
	applicationProcessCode: string;
	issuedAt: string;
	concessionaireName: string;
	proprietorName: string;
	interventionTypeName: string;
	analysisStartDatetime: string;
	analysisLimitDatetime: string;
	highwayCode: string;
	currentResponsibleUserName: string | null;
	status: InternalUserStatusLabel;
};

const headerClassName =
	"flex items-center justify-start border-0 text-xs font-semi text-neutral-low-pure-500 h-12 p-2";
const cellClassName =
	"flex items-center justify-start border-0 text-sm text-neutral-low-300 h-12 p-2";

type ApplicationListItemColumn = ColumnDef<
	ApplicationListItem,
	ApplicationListItem[keyof ApplicationListItem]
>;

function ActionsCell({
	value
}: Readonly<{ value: string | null }>): JSX.Element {
	const navigate = useNavigate();
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<Button
				type="button"
				kind="icon"
				hierarchy="ghost"
				className="detail-button"
				onClick={() => {
					navigate(
						`/backoffice/solicitacao/${value}/${ApplicationPageTabs.DETAILS}`
					);
				}}
			>
				<Tooltip anchorSelect=".detail-button" place="top">
					Detalhes
				</Tooltip>
				<IcoFile />
			</Button>
		</div>
	);
}

const applicationColumns: ApplicationListItemColumn[] =
	TableHelper.addClassNames(
		[
			{
				accessorKey: "applicationProcessCode",
				header: "Nº do processo",
				cell: asCellRenderer(BasicValueDisplay),
				size: 6
			},
			{
				accessorKey: "issuedAt",
				header: "Data de criação",
				cell: asCellRenderer<ApplicationListItem, "issuedAt">(
					LocaleDateShortValueDisplay
				),
				size: 4
			},
			{
				accessorKey: "analysisStartDatetime",
				header: "Início da análise",
				cell: asCellRenderer<ApplicationListItem, "analysisStartDatetime">(
					NaiveDateValueDisplay
				),
				size: 4
			},
			{
				accessorKey: "concessionaireName",
				header: "Concessionária",
				cell: asCellRenderer(BasicValueDisplay),
				size: 6
			},
			{
				accessorKey: "proprietorName",
				header: "Solicitante",
				cell: asCellRenderer(BasicValueDisplay),
				size: 7
			},
			{
				accessorKey: "interventionTypeName",
				header: "Tipo da intervenção",
				cell: asCellRenderer(({ value }) => (
					<TooltipWrapper place="top" value={value} />
				)),
				size: 7
			},
			{
				accessorKey: "analysisLimitDatetime",
				header: "Prazo da análise",
				cell: asCellRenderer(DeadlineDisplay),
				size: 8
			},
			{
				accessorKey: "highwayCode",
				header: "Rodovia",
				cell: asCellRenderer(BasicValueDisplay),
				size: 5
			},
			{
				accessorKey: "currentResponsibleUserName",
				header: "Analista",
				cell: asCellRenderer(NameValueDisplay),
				size: 5
			},
			{
				accessorKey: "status",
				header: "Status",
				cell: asCellRenderer(ApplicationListStatusDisplay),
				size: 5
			},
			{
				accessorKey: "id",
				header: "Ações",
				cell: asCellRenderer(ActionsCell),
				size: 2
			}
		],
		cellClassName,
		headerClassName
	);

const emptyFilters: ApplicationListFilters = {
	from: "",
	to: "",
	statuses: [],
	interventionTypes: [],
	concessionaires: [],
	responsibleGroups: []
};

export default function BackofficeApplicationList(): JSX.Element {
	const [urlParams, setUrlParams] = useSearchParams();

	const paginationTools = usePagination();
	const { stablePage, pageItemCount, setPageCount } = paginationTools;

	const {
		setValue: setSearchText,
		stableValue: stableSearchText,
		unstableValue: searchText
	} = useStabilizedState<string>(urlParams.get("search") ?? "", 750);
	const searchTools = {
		setSearchText: (newSearchText: string) => {
			setSearchText(newSearchText);
			paginationTools.setPage(Constants.INITIAL_PAGE_INDEX);
		},
		stableSearchText,
		searchText
	};
	useEffect(() => {
		const newParams = new URLSearchParams(urlParams);
		newParams.set("search", stableSearchText);
		setUrlParams(newParams);
	}, [stableSearchText]);

	const applicationService = useMemo(ApplicationService.getInstance, []);

	const [isLoadingFilterOptions, setIsLoadingFilterOptions] =
		useState<boolean>(false);
	const [filterOptions, setFilterOptions] =
		useState<ApplicationFilterOptions | null>(null);

	const [filters, setFilters] = useUrlFilters(
		emptyFilters,
		urlParams,
		(newParams) => {
			paginationTools.setPage(Constants.INITIAL_PAGE_INDEX);
			setUrlParams(newParams);
		},
		ApplicationService.parseBackofficeListFilterParams,
		ApplicationService.makeBackofficeApplicationFilterParams
	);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
	const filterTools: FilterTools<ApplicationListFilters> = {
		filters,
		initialFilters: emptyFilters,
		resetFilters: () => setFilters(emptyFilters),
		setFilters,
		setModalOpen: setIsFilterModalOpen
	};

	const [items, setItems] = useState<ApplicationListItem[] | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const loadData = useCallback(() => {
		setIsLoading(true);
		applicationService
			.listBackofficeApplications(
				stablePage - 1,
				pageItemCount,
				stableSearchText,
				filters
			)
			.then((responseData) => {
				const { totalPages, content } = responseData;
				setPageCount(totalPages);
				setItems(content);
			})
			.catch((error) => toast.error(ErrorHelper.getResponseErrorMessage(error)))
			.finally(() => setIsLoading(false));
	}, [
		stablePage,
		pageItemCount,
		stableSearchText,
		filters,
		setPageCount,
		setItems,
		setIsLoading,
		applicationService
	]);

	useEffect(() => {
		if (!isLoading) {
			loadData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadData]);

	return (
		<div className="h-full">
			<BackofficeList
				columns={applicationColumns}
				items={items ?? []}
				loading={isLoading}
				title="Atividades"
				paginationTools={paginationTools}
				filterTools={filterTools}
				searchTools={searchTools}
			/>
			{isFilterModalOpen && (
				<BackofficeApplicationListFilterForm
					setFilters={setFilters}
					clearFilters={() => {
						setFilters(emptyFilters);
						setSearchText("");
					}}
					initialFilters={filters}
					isOpen={isFilterModalOpen}
					setIsOpen={setIsFilterModalOpen}
					filterOptions={filterOptions}
					setFilterOptions={setFilterOptions}
					isLoadingFilterOptions={isLoadingFilterOptions}
					setIsLoadingFilterOptions={setIsLoadingFilterOptions}
					applicationService={applicationService}
				/>
			)}
		</div>
	);
}
